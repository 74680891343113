import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { Component, Prop, Watch } from "vue-property-decorator";
import * as types from "@board/store/types";
import * as accountTypes from "@account/store/types";
import { getProperty, getLangProperty } from "@appbase/util";
import {
  createEventBus,
  listenEvent,
  removeEventBus,
} from "@appbase/util/eventBus";
import { loadScript } from "@appbase/util/script-loader";
import menuHelper from "@appbase/util/menu-helper";
import boardApi from "@board/api";
import { open, OpenType } from "@board/open-handler";

import SearchBox from "@board/components/common/SearchBox.vue";
import Paginate from "@board/components/common/Paginate.vue";
import { LoadingBar } from "@appbase/components/loading";

//2022-08-02
// viewCount interface
interface viewCountItem {
  name: string;
  value: number;
}

@Component({
  components: {
    SearchBox,
    Paginate,
    LoadingBar,
  },
  computed: mapGetters({
    popupBaseUrl: types.POPUP_BASEURL,
    boardConfig: types.BOARD_CONFIG,
    contentFields: types.FETCH_CONTENTFIELD,
    pageSet: types.FETCH_PAGESET,
    user: accountTypes.USER,
    current: accountTypes.CURRENT,
  }),
  methods: {
    ...mapActions({
      fetchBoard: types.FETCH_BOARD,
      fetchArticle: types.FETCH_ARTICLES,
      fetchBlogArticle: types.FETCH_BLOGARTICLES,
      fetchPageSet: types.FETCH_PAGESET,
    }),
  },
})
export default class BoardMain extends Vue {
  @Prop({ type: Boolean }) adminMode!: boolean;

  fetchBoard!: (boardId: number) => Promise<board.BoardItem>;
  fetchArticle!: (
    searchset: article.SearchSet
  ) => Promise<article.PagedData<article.SearchSet>>;
  fetchBlogArticle!: (
    searchset: article.SearchSet
  ) => Promise<article.PagedData<article.SearchSet>>;
  fetchPageSet!: (pageSet: boardModule.state.PageSet) => void;

  user!: account.User;
  popupBaseUrl!: string;
  pageSet!: boardModule.state.PageSet;
  boardConfig!: board.BoardItem;
  contentFields!: board.ContentField[];
  current!: account.TCurrent;

  totalPage: number = 0;
  totalCount: number = 0;
  itemsPerPage: number = 15;
  currentPath: string = "";
  currentBoard: number = 0;
  currentArticle: number = 0;
  ofString: string = "";
  selectedArticles: article.Article[] = [];

  writePermission: boolean = false;
  deletePermission: boolean = false;

  prefixRoute: string = "boardmodule";

  //표시항목개수 v-model
  viewCountItems: Array<viewCountItem> = [
    // "20개씩 보기"
    { name: this.$t("LR_20개씩_보기").toString(), value: 20 },
    { name: this.$t("LR_30개씩_보기").toString(), value: 30 },
    { name: this.$t("LR_40개씩_보기").toString(), value: 40 },
    { name: this.$t("LR_50개씩_보기").toString(), value: 50 },
  ];
  selectedViewCount: number = 0;

  get boardName() {
    if (this.boardConfig == null) return "";
    else return getLangProperty(this.boardConfig, "boardName");
  }

  get searchBoxCriterias() {
    if (this.boardConfig == null) {
      return [];
    } else {
      var searchFields = this.contentFields.filter((f) => f.isSearchField);
      return searchFields.map(
        (m) =>
          <common.SearchBoxCriteria>{
            value: m.fieldId.toString(),
            //displayName: getLangProperty(m, 'displayName'),
            displayName: this.$t(m.displayName).toString(),
          }
      );
    }
  }

  get showNavigatePane() {
    //지누스 헬프테스트 인경우 제외
    if (
      this.boardConfig == null ||
      this.adminMode ||
      this.boardConfig.zIsHelpDesk == true
    ) {
      return false;
    } else {
      return !this.boardConfig.useFixedView;
    }
  }

  @Watch("boardConfig")
  onBoardConfigChanged(newVal: board.BoardItem) {
    const { language } = this.current.userConfig;
    var title = language === "ko-KR" ? newVal.boardName : newVal.boardName_En;
    // 게시판명이 아닌 메뉴명으로 다국어 적용해서 title 셋팅
    const menu = menuHelper.findOneBySysId(newVal.boardId, "BB");
    if (menu) {
      title = this.$t(menu.menuName).toString();
    }
    this.setPage({ title });
  }

  @Watch("$route.fullPath")
  onRouteChanged(newVal: string) {
    var { SYSID, MENUID } = this.$route.query;

    if (
      (SYSID && this.boardConfig.boardId != Number(SYSID)) ||
      this.$route.name === "boardmodule-main"
    ) {
      this.mountPermission(Number(MENUID));
      this.mountAction();
    }

    this.currentPath = this.$route.name || "";
  }

  created() {
    //법인게시판 프록시 인경우
    //board prefix 재 정의
    if (this.$route.path.indexOf("/boardproxy") > -1)
      this.prefixRoute = "boardproxy";

    var { MENUID } = this.$route.query;
    var _this = this;
    createEventBus(window, "boardlist-reload", this.handleReload);

    this.mountPermission(Number(MENUID));

    if ((window as any).eumBoardIds) {
      this.mountAction();
    } else {
      loadScript(
        "/cm/gres/js/boardFieldSetting.js",
        "eum-board-setting",
        function () {
          _this.mountAction();
        }
      );
    }
  }

  beforeDestroy() {
    removeEventBus(window, "boardlist-reload", this.handleReload);
  }

  handleReload() {
    this.fetchPageSet(<boardModule.state.PageSet>{
      boardId: this.currentBoard,
      currentPage: this.pageSet.currentPage,
      searchField: this.pageSet.searchField,
      searchText: this.pageSet.searchText,
      preface1: this.pageSet.preface1,
      preface2: this.pageSet.preface2,
      useNotice: this.pageSet.useNotice,
    });
  }

  initViewCountControl() {
    if (this.viewCountItems.length > 0) {
      this.selectedViewCount = this.viewCountItems[0].value;
    }
  }

  mountAction() {
    //2022-08-02 표시항목 컨트롤 초기화
    this.initViewCountControl();

    if (this.$route.query) {
      var query = this.$route.query;

      if (!query.SYSID) {
        return;
      }

      this.currentBoard = Number(query.SYSID);

      if (
        this.boardConfig == null ||
        !(this.boardConfig.boardId == this.currentBoard)
      ) {
        this.fetchBoard(this.currentBoard).then((res) => {
          this.determineViewRoute(res);
        });
      } else {
        this.determineViewRoute(this.boardConfig);
      }
    }

    this.currentPath = this.$route.name || "";
  }

  determineViewRoute(config: board.Board) {
    if (
      this.$route.path.endsWith("/read") ||
      this.$route.path.endsWith("/write")
    )
      return;

    // if(this.$route.path.indexOf("/boardproxy") > -1) return

    // let prefixRoute = "boardmodule"

    // if(this.$route.path.indexOf("/boardproxy") > -1) prefixRoute ="boardproxy"

    var routeName = `${this.prefixRoute}-listview`;

    if (this.adminMode) {
      routeName = `${this.prefixRoute}-adminlistview`;
    } else {
      switch (config.defaultView) {
        case "AL":
          routeName = `${this.prefixRoute}-albumview`;
          break;
        case "BL":
          routeName = `${this.prefixRoute}-summaryview`;
          break;
        default:
          routeName = `${this.prefixRoute}-listview`;
          break;
      }
    }

    this.$router
      .push({
        name: routeName,
        query: this.$route.query,
      })
      .catch((err) => {});
  }

  mountPermission(menuId: number) {
    var currentMenu = this.current.permissions.menues.find(
      (m) => m.id == menuId
    );

    if (currentMenu) {
      this.writePermission = currentMenu.c;
      this.deletePermission = currentMenu.d;
    } else {
      this.writePermission = false;
      this.deletePermission = false;
    }
  }

  getViewBtnClass(name: string) {
    var btnClass: any = {};
    if (this.currentPath === name) {
      btnClass["btn-green"] = true;
    } else {
      btnClass["btn-green-outline"] = true;
    }
    return btnClass;
  }

  getArticleFieldValue(article: article.Article, field: board.BoardField) {
    if (field.fieldValueName != null) {
      return getProperty(article, field.fieldValueName);
    } else {
      var FieldValue = article.fieldValues.find(
        (f) => f.fieldId == field.fieldId
      );
      return FieldValue?.value;
    }
  }

  pageClickCallback(page: number) {
    let payload: boardModule.state.PageSet = {
      boardId: this.currentBoard,
      currentPage: page,
      searchField: this.pageSet.searchField,
      searchText: this.pageSet.searchText,
      preface1: this.pageSet.preface1,
      preface2: this.pageSet.preface2,
      useNotice: this.pageSet.useNotice,
    };
    // HelpDesk pageSet merge
    if (this.boardConfig.zIsHelpDesk) {
      payload = { ...this.pageSet, ...payload };
    }

    this.fetchPageSet(payload);
  }

  searchClick(
    selected: string,
    searchText: string,
    preface1: string | null,
    preface2: string | null,
    helpdeskStatus?: number,
    helpdeskCategory?: number,
    helpdeskDateType?: number,
    startDate?: string,
    endDate?: string
  ) {
    let payload: boardModule.state.PageSet = {
      boardId: this.currentBoard,
      currentPage: 1,
      searchField: Number(selected),
      searchText: searchText,
      preface1: preface1,
      preface2: preface2,
      useNotice: this.boardConfig.usePrimaryNotice,
    };

    if (this.boardConfig.zIsHelpDesk) {
      payload.helpdeskCategory = helpdeskCategory;
      payload.helpdeskStatus = helpdeskStatus;
      payload.helpdeskDateType = helpdeskDateType;
      payload.startDate = startDate;
      payload.endDate = endDate;
    }

    this.fetchPageSet(payload);
  }

  prefaceChanged(preface1: string | null, preface2: string | null) {
    this.fetchPageSet(<boardModule.state.PageSet>{
      boardId: this.currentBoard,
      currentPage: 1,
      searchField: 0,
      searchText: null,
      preface1: preface1,
      preface2: preface2,
      useNotice: this.boardConfig.usePrimaryNotice,
    });
  }

  onArticlesChanged(
    itemsPerPage: number,
    totalCount: number,
    page: number,
    itemCount: number
  ) {
    this.totalCount = totalCount;
    this.totalPage = Math.ceil(totalCount / itemsPerPage);

    var startIdx = (page - 1) * itemsPerPage + 1;
    this.ofString = `${startIdx} - ${
      startIdx + itemCount - 1
    } of ${totalCount}`;
  }

  goWritePage() {
    if (this.adminMode) {
      open(OpenType.PopupWrite, {
        boardId: this.boardConfig.boardId,
        baseUrl: this.popupBaseUrl,
      });
    } else {
      let openType =
        this.isTeams || !this.boardConfig.usePopup
          ? OpenType.ModalWrite
          : OpenType.PopupWrite;

      //헬프데스크인경우 변경
      //헬프테스트 인 경우 헬프데스크용 팝업
      if (this.boardConfig.zIsHelpDesk == true) {
        openType =
          this.isTeams == true
            ? OpenType.HelpdeskModalWrite
            : OpenType.HelpdeskPopupWrite;
        // openType =   OpenType.HelpdeskModalWrite
      }

      open(openType, {
        boardId: this.boardConfig.boardId,
        baseUrl: this.popupBaseUrl,
      });
    }
  }

  async onRestoreClick() {
    if (await(window as any).confirmAsync(this.$t("선택_게시글_복원").toString())) {
      boardApi.article
        .restoreArticles(this.selectedArticles.map((a) => a.articleId))
        .then(async (res) => {
          await (window as any).alertAsync(this.$t("복원_완료").toString());
        })
        .catch(async (err) => {
          await (window as any).alertAsync(this.$t("복원_실패").toString());
        })
        .finally(() => {
          this.handleReload();
        });
    }
  }

  async onDeleteClick(hardDelete: boolean) {
    var confirmMessage = hardDelete
      ? this.$t("선택_게시글_완전삭제").toString()
      : this.$t("선택_게시글_삭제").toString();

    if (await(window as any).confirmAsync(confirmMessage)) {
      boardApi.article
        .deleteArticles({
          ids: this.selectedArticles.map((a) => a.articleId),
          pernamentDelete: hardDelete,
        })
        .then(async (res) => {
          await (window as any).alertAsync(this.$t("M_Common_Deleted").toString());
        })
        .catch(async (err) => {
          await (window as any).alertAsync(this.$t("삭제실패").toString());
        })
        .finally(() => {
          this.handleReload();
        });
    }
  }

  async onToggleSecretClick() {
    if (await(window as any).confirmAsync(this.$t("공개여부_전환_확인").toString())) {
      boardApi.article
        .toggleArticlesSecret(this.selectedArticles.map((a) => a.articleId))
        .then(async (res) => {
          await (window as any).alertAsync(this.$t("전환_완료").toString());
        })
        .catch(async (err) => {
          await (window as any).alertAsync(this.$t("전환_실패").toString());
        })
        .finally(() => {
          this.handleReload();
        });
    }
  }

  onLayoutChangeClick(type: string) {
    this.$router.push({
      name: type,
      query: this.$route.query,
    });
  }
}
