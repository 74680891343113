import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex';
import * as types from '@board/store/types'

@Component({
    computed: mapGetters({
        pageSet: types.FETCH_PAGESET
    })
})
export default class Paginate  extends Vue {
    @Prop({ type: Number }) totalPage!: number;
    @Prop({ type: Number }) pageRange!: number;

    pageSet!: boardModule.state.PageSet;

    currentPage: number = 1;
    pageNumbers: number[] = [];

    @Watch('totalPage')
    onTotalPageChanged(val: number) {
        this.updatePageNumbers();
    }

    @Watch('pageSet')
    onPageSetChanged(newVal: boardModule.state.PageSet) {
        this.currentPage = newVal.currentPage;
        this.updatePageNumbers();
    }

    created() {          
        this.updatePageNumbers();
    }

    updatePageNumbers() {
        this.pageNumbers = [];

        var startPage = 1;
        var endPage = 1;

       if(this.totalPage <= this.pageRange) {
            startPage = 1;
            endPage = this.totalPage;
       } else {
           var leftShift = Math.floor(this.pageRange/2);
           var rightShift = Math.floor(this.pageRange/2);

           if(this.currentPage <= leftShift) {
               startPage = 1;
               endPage = this.pageRange;
           } else if (this.currentPage + rightShift >= this.totalPage) {
               startPage = this.totalPage - this.pageRange + 1;
               endPage = this.totalPage;
           } else {
               startPage = this.currentPage - leftShift;
               endPage = this.currentPage + rightShift;
           }
       }

       for(var i = startPage; i <= endPage; i++) {
           this.pageNumbers.push(i);
       }
    }

    goTo(page: number) {
        this.$emit('goTo', page);
        this.currentPage = page;
    }

    goFirst() {
        this.$emit('goTo', 1);
        this.currentPage = 1;
    }

    goPrev() {
        this.$emit('goTo', this.currentPage - 1 < 1 ? 1 : this.currentPage - 1);
        this.currentPage = this.currentPage - 1;
    }

    goNext() {
        this.$emit('goTo', this.currentPage + 1 > this.totalPage ? this.totalPage : this.currentPage + 1);
        this.currentPage = this.currentPage + 1;
    }

    goLast() {
        this.$emit('goTo', this.totalPage);
        this.currentPage = this.totalPage;
    }
}