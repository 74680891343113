import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import * as accountTypes from "@account/store/types";
import * as types from "@board/store/types";
import { HelpdeskDateType, HelpdeskStatus } from "@board/types/helpdesk";
import api from "@board/api/helpdesk";
import * as dateutil from "@appbase/util/date-helper";
import DatePicker from "@appbase/components/DatePicker/DatePicker.vue";
import { ko } from "vuejs-datepicker/dist/locale";
interface HelpdeskStatusObject {
  value: HelpdeskStatus | HelpdeskDateType | any;
  text: string;
}

@Component({
  components: { DatePicker },
  computed: mapGetters({
    boardConfig: types.BOARD_CONFIG,
    user: accountTypes.USER,
    coperationBoardInfo: types.COPERATION_BOARD_INFO,
    helpdeskCategories: types.Z_HELPDESK_CATEGORIES,
  }),
})
export default class SearchBox extends Vue {
  @Prop({ type: Array }) criterias!: common.SearchBoxCriteria[];

  user!: account.User;
  coperationBoardInfo!: Array<board.CoperationBoard>;
  helpdeskCategories!: Array<helpdesk.Category>;
  helpdeskCriterias: Array<common.SearchBoxCriteria> = [];

  @Watch("criterias")
  criteriasChanged(newVal: common.SearchBoxCriteria[]) {
    if (newVal != null && newVal.length > 0) {
      // 2023-08-28 익명 게시판의 경우 검색 영역의 작성자 필터 삭제처리
      if (this.boardConfig && this.boardConfig.useAnonComment){
        for(var i = 0; i < newVal.length; i++){
          if (newVal[i].value == "4") {
            newVal.splice(i, 1);
            break;
          }
        }
      }
      
      this.mSearchField = newVal[0].value;
    }
  }

  @Watch("boardConfig")
  boardConfigChanged(newVal: board.BoardItem) {
    if (newVal != null) {
      var prefaces = newVal.usePreface ? newVal.prefaces : null;
      this.mountPreface(prefaces);
    } else {
      this.mShowParentPreface = false;
      this.mShowChildPreface = false;
    }

    // [2023-04-06] IT 헬프데스크 검색 필터 초기화 및 Dom 요소 없는 경우 예외처리
    // 게시판 변경 시 검색어 Text 초기화
    var inpElem = this.$refs.refInpSearch as HTMLInputElement;
    if (inpElem) inpElem.value = "";
    this.isSearch = false;

    //IT Helpdesk 검색 필터 초기화
    this.selectedHelpdeskStatus = 999;
    this.selectedHelpdeskDate = 0;
    this.selectedHelpdeskCategory = 0;
  }

  boardConfig!: board.BoardItem;

  mSearchField: string = "";

  mShowParentPreface: boolean = false;
  mShowChildPreface: boolean = false;
  parentPrefaceId: string = "";
  childPrefaceId: string = "";
  mParentPrefaces: board.BoardPreface[] = [];
  mChildPrefaces: board.BoardPreface[] = [];

  isSearch: boolean = false;

  isHelpdesk: boolean = false;
  helpdeskStatusFilters: Array<HelpdeskStatusObject> = [];
  selectedHelpdeskStatus: number = 999;
  selectedHelpdeskCategory: number = 0;
  selectedHelpdeskDate: number = 0;

  // for date picker
  startDate: string = dateutil.uGetDateFormat(
    "yyyy-MM-DD",
    new Date(dateutil.uGetDate(-3, new Date(), "month"))
  );
  endDate: string = dateutil.uGetDateFormat("yyyy-MM-DD", new Date());

  helpdeskDateFilter() {
    return [
      { value: HelpdeskDateType.Create, text: this.$t("작성일") },
      { value: HelpdeskDateType.Complete, text: this.$t("LR_DUE_BY") },
    ];
  }

  getHelpdeskCriterias() {
    if (this.boardConfig && this.boardConfig.zIsHelpDesk == true) {
      return [
        ...this.criterias,
        { value: 900, displayName: this.$t("TicketNo").toString() },
        { value: 999, displayName: this.$t("담당자").toString() },
      ];
    } else {
      return this.criterias;
    }
  }

  getHelpdeskCategoryItems() {
    let result = this.helpdeskCategories.filter((item) => {
      return item.categoryLevel == 1;
    });

    result.unshift({
      categoryId: 0,
      categoryLevel: 1,
      categoryName: this.$t("LR_전체_카테고리").toString(),
      categoryType: "category",
      isUse: true,
      parentId: 0,
      formHtml: "",
    });

    return result;
  }

  async onChangedCoperationBoard(evt: any) {
    let val = evt.target.value as string;

    let myCoperation = this.coperationBoardInfo.find((c) => {
      return c.coperationCode == val;
    });

    if (myCoperation) {
      let querySet = {
        MENUID: myCoperation.menuId.toString(),
        SYSID: myCoperation.boardId.toString(),
      };

      this.$router.push({
        ...this.$router,
        query: querySet,
      });
      await (window as any).alertAsync("법인게시판이 변경되었습니다.");
    }
  }

  async created() {
    //법인 게시판인지 확인함
    //boardproxy로 인입된 경우 법인 게시판으로 간주 함
    if (this.$route.path.indexOf("/boardproxy") > -1) {
      // 위 두개에 값은 프록시 (/boardproxy/proxy) 에서 확인 후 store 에 저장하는게 이득일듯?
      await (window as any).alertAsync(
        "검색 필터에 법인 선택은 권한이 있는 법인 목록만 표시되며,\n별도에 권한이 없는 사용자인 경우 필터표지 하지 않는다."
      );
    }

    this.initStatusFilter();

    this.criteriasChanged(this.criterias);
    this.boardConfigChanged(this.boardConfig);
    this.onChnagedDateFilters();
  }

  initStatusFilter() {
    this.helpdeskStatusFilters = [
      {
        value: HelpdeskStatus.NotSet,
        text: this.$t("LR_전체_상태").toString(),
      },
      {
        value: HelpdeskStatus.Registration,
        text: this.$t("등록").toString(),
      },
      {
        value: HelpdeskStatus.Receive,
        text: this.$t("접수").toString(),
      },
      {
        value: HelpdeskStatus.Assign,
        text: this.$t("담당자배정").toString(),
      },
      {
        value: HelpdeskStatus.Completed,
        text: this.$t("처리완료").toString(),
      },
      {
        value: HelpdeskStatus.Reject,
        text: this.$t("LR_거절").toString(),
      },
      {
        value: HelpdeskStatus.Hold,
        text: this.$t("LR_보류").toString(),
      },
      {
        value: HelpdeskStatus.ExceptCompleted,
        text: this.$t("LR_완료제외").toString(),
      },
    ];
  }

  mountPreface(prefaces: board.BoardPreface[] | null) {
    if (prefaces == null) {
      this.mShowParentPreface = false;
      this.mShowChildPreface = false;
    } else {
      this.mShowChildPreface = true;
      this.mChildPrefaces = prefaces.filter((p) => p.parentPrefaceId != null);

      if (this.mChildPrefaces.length > 0) {
        this.mShowParentPreface = true;
        this.mParentPrefaces = prefaces.filter(
          (p) => p.parentPrefaceId == null
        );
      } else {
        this.mShowParentPreface = false;
        this.mChildPrefaces = prefaces;
      }
    }
  }

  onParentPrefaceChanged() {
    if (this.boardConfig != null) {
      var prefaces = this.boardConfig.prefaces;
      if (this.parentPrefaceId === "") {
        this.mChildPrefaces = prefaces.filter((p) => p.parentPrefaceId != null);
      } else {
        this.mChildPrefaces = prefaces.filter(
          (p) => p.parentPrefaceId === this.parentPrefaceId
        );
      }
      this.childPrefaceId = "";
      var returnId = this.parentPrefaceId === "" ? null : this.parentPrefaceId;
      this.$emit("filterChanged", null, returnId);
    }
  }

  onChildPrefaceChanged() {
    var returnId2 = this.parentPrefaceId === "" ? null : this.parentPrefaceId;
    var returnId1 = this.childPrefaceId === "" ? null : this.childPrefaceId;
    this.$emit("filterChanged", returnId1, returnId2);
  }

  onClickSearch() {
    var inpElem = this.$refs.refInpSearch as HTMLInputElement;

    if (inpElem.value) this.isSearch = true;
    else this.isSearch = false;

    var returnId2 = this.parentPrefaceId === "" ? null : this.parentPrefaceId;
    var returnId1 = this.childPrefaceId === "" ? null : this.childPrefaceId;

    if (this.boardConfig.zIsHelpDesk == true) {
      this.$emit(
        "searchClickCallback",
        this.mSearchField,
        inpElem.value,
        returnId1,
        returnId2,
        this.selectedHelpdeskStatus,
        this.selectedHelpdeskCategory,
        this.selectedHelpdeskDate,
        this.startDate,
        this.endDate
      );
    } else {
      this.$emit(
        "searchClickCallback",
        this.mSearchField,
        inpElem.value,
        returnId1,
        returnId2
      );
    }
  }

  // 검색 초기화
  onClickSearchReset() {
    this.mSearchField =
      this.criterias && this.criterias.length > 0
        ? this.criterias[0].value
        : "";
    (this.$refs.refInpSearch as HTMLInputElement).value = "";
    this.parentPrefaceId = "";
    this.childPrefaceId = "";

    this.onClickSearch();
  }

  // [2023-04-06] IT 헬프데스크 필터 선택 시 바로 적용
  @Watch("selectedHelpdeskStatus")
  @Watch("selectedHelpdeskCategory")
  onChangedHelpdeskFilter() {
    this.onClickSearch();
  }

  // 필터 변경 시 초기값으로
  @Watch("selectedHelpdeskDate")
  onChnagedDateFilters() {
    this.startDate = dateutil.uGetDateFormat(
      "yyyy-MM-DD",
      new Date(dateutil.uGetDate(-3, new Date(), "month"))
    );
    this.endDate = dateutil.uGetDateFormat("yyyy-MM-DD", new Date());
  }

  getExcelList() {
    var inpElem = this.$refs.refInpSearch as HTMLInputElement;
    var searchFilter = this.mSearchField == "" ? "0" : this.mSearchField;
    api.downloadListExcel(
      this.boardConfig.boardId,
      this.user.id,
      this.selectedHelpdeskStatus,
      this.selectedHelpdeskCategory,
      this.selectedHelpdeskDate,
      this.startDate,
      this.endDate,
      inpElem.value ? inpElem.value : "",
      searchFilter
    );
  }
}
