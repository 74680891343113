export {};
declare global {
  namespace helpdesk {
    export interface Category {
      categoryId: number;
      categoryType: string;
      parentId: number;
      categoryLevel: number;
      categoryName: string;
      isUse: boolean;
      formHtml: string;
    }

    export interface CategoryValue {
      lv1: number;
      lv2: number;
      service: number;
      formHtml: string;
    }

    export interface ResponseHelpdeskCase extends RequestHelpdeskCase {}

    export interface RequestHelpdeskCase {
      detail: HelpdeskMain;
      managers: Array<HelpdeskManager>;
      menuId?: number;
      subject?: string;
      boardContent?: string;
      timezoneOffset?: number;
    }

    export interface HelpdeskMain {
      helpdeskId?: number;
      serverity: number | HelpdeskServerityType | string;
      boardId: number;
      articleId: number;
      categoryLv1Id: number;
      categoryLv2Id: number;
      serviceTypeId: number;
      requestDate: Date | null;
      dueByDate: Date | null;
      status: HelpdeskStatus | number | string;
      ticketNo: string;
      helpdeskContent: string;
      fileCode: string;
      isDeleted?: boolean;
      managerPersonCode?: string;
      managerDisplayName?: string;
      authorCompanyName?: string;

      requestCompleteDate: Date | null;
      alarmAddTarget?: string;
      alarmAddTargetDisplayInfo?: string;
    }

    export interface HelpdeskManager {
      helpdeskManagerId?: number;
      helpdeskId?: number;
      personCode: string;
      displayName?: string;
    }

    export interface HelpdeskServerity {
      value: HelpdeskServerityType;
      name: string;
      description: string;
    }

    export interface HelpdeskNoticeOption {
      noticeTemplate: string;
      useNotice: boolean;
      notiType: string;
    }
  }
}
export enum HelpdeskServerityType {
  None = 0,
  Low = 1,
  Moderate = 2,
  High = 3,
  Critical = 4,
}
export enum HelpdeskStatus {
  Registration = 0, //등록
  Receive = 1, //접수
  Assign = 2, //담당자배정
  Completed = 3, //처리완료
  Reject = 4,
  Hold = 5,
  ExceptCompleted = 6,
  NotSet = 999,
}

export enum HelpdeskDateType {
  Create = 0, //작성일
  Complete = 1, //완료예정일
}

export enum HelpdeskCategoryType {
  Lv1 = 1,
  Lv2 = 2,
  Service = 3,
}

// public int CategoryId { get; set; }
// public string CategoryType { get; set; }
// public int ParentId { get; set; }
// public int CategoryLevel { get; set; }
// public string CategoryName { get; set; }
// public bool IsUse { get; set; }
